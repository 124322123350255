import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ConfirmationModal } from './ConfirmationModal';
import ProductPrice from './ProductPrice';
import { ReplacementOrderModal } from './ReplacementOrderModal';
import { FulfillmentsTable } from './Table';
import { InfoTooltip } from './Tooltip';
import { axios } from '../api';
import { Pencil } from '../assets/Pencil';
import { TRACKING_URL } from '../constants';
import { calculatePriceAfterDiscountWithTax, parseNumber } from '../financialUtils';
import { Customer, Fulfillment, FulfillmentStatus } from '../pages/types';
import { FulfillmentHeader } from '../tableHeaders';
import { BOTTOM_TOAST } from '../toastUtils';

interface OrderFulfillmentsProps {
  customer: Customer;
  order: any;
  refreshCustomer: () => void;
}

const mapFulfillments = (order: any) =>
  order.fulfillments
    .map((fulfillment: any) =>
      fulfillment.products.map((product: any) => ({
        ...product,
        isMealProduct: product.type === 'MEAL',
        tax_amount: parseNumber(
          order.products.find((orderProduct: any) => orderProduct.code === product.code)?.tax_amount
        ),
        fulfillment,
        order,
        unit_price: product.price.toFixed(2),
        discount_amount: parseNumber(product.discount_amount).toFixed(2),
      }))
    )
    .flat()
    .sort((a: any, b: any) => a.fulfillment.id.localeCompare(b.fulfillment.id));

const getPetName = (order: any, fulfillment: Fulfillment) => {
  let petNamesStr = '';
  const petNames: string[] = [];
  if (fulfillment.products.length > 0) {
    order.products.forEach((orderProduct: any) => {
      const fulfillmentProducts = fulfillment.products.map(
        (fulfillmentProduct: any) => fulfillmentProduct.code
      );
      if (fulfillmentProducts.includes(orderProduct.code)) {
        const petName = orderProduct?.pet_plan?.pet_name;
        if (petName && !petNames.includes(petName)) {
          petNames.push(petName);
        }
      }
    });

    // Create a deduplicated string of pet names
    petNamesStr = petNames.join(', ');
  }
  return petNamesStr === '' ? 'Noname' : petNamesStr;
};

const getPetNameFromPlanId = (orderProducts: any, petId: string) => {
  if (!petId) {
    return '--';
  }
  const product = orderProducts.find((orderProduct: any) => orderProduct.pet_plan.pet_id === petId);
  return product?.pet_plan.pet_name;
};

const getShipmentTooltip = (shipment: any | undefined) => {
  if (!shipment) return 'No tracking information';

  const statusFields = [
    'shipped',
    'in_transit',
    'out_for_delivery',
    'available_for_pickup',
    'delivered',
    'voided',
    'returned',
    'exception',
  ];

  let mostRecentStatus: string | undefined;
  let mostRecentTimestamp: Date | undefined;

  for (const status of statusFields) {
    const timestamp = shipment[status];
    if (
      timestamp &&
      (!mostRecentTimestamp || new Date(timestamp) > new Date(mostRecentTimestamp))
    ) {
      mostRecentTimestamp = new Date(timestamp);
      mostRecentStatus = status;
    }
  }

  if (!mostRecentStatus) {
    return 'No tracking information';
  }

  return (
    <div className="text-left">
      Last update from AfterShip <br /> {mostRecentStatus} on{' '}
      {mostRecentTimestamp?.toLocaleString()}
    </div>
  );
};

const getTrackingNumber = (product: any) => {
  if (
    product.fulfillment.is_split_fulfillment ||
    (product.isMealProduct && product.fulfillment?.shipments?.length > 0)
  ) {
    return (
      <ul>
        {product.fulfillment.shipments.map((shipment: any) => (
          <li key={shipment.tracking_number} className="flex items-center">
            <a
              onClick={() => window.open(`${TRACKING_URL}/${shipment.tracking_number}`)}
              className="cursor-pointer underline text-anchor mr-2"
            >
              {shipment.tracking_number}
            </a>
            <InfoTooltip text={getShipmentTooltip(shipment)} />
          </li>
        ))}
      </ul>
    );
  }
  return '--';
};

export const useSplitFulfillment = () => {
  const navigate = useNavigate();

  return (fulfillmentId: number) => {
    const curr = window.location.pathname;
    const fulfillmentPath = `${curr}/${fulfillmentId}/split-fulfillment`;
    navigate(fulfillmentPath);
  };
};
export const OrderFulfillments = ({ customer, order, refreshCustomer }: OrderFulfillmentsProps) => {
  const [selectedFulfilmentId, setSelectedFulfilmentId] = useState(null);
  const [isProcessLoading, setIsProcessLoading] = useState(false);

  const canReplaceOrder =
    order.order_type !== 'REPLACEMENT' &&
    ['PARTIALLY_FULFILLED', 'FULFILLED', 'COMPLETE', 'CANCELLED'].some((status) =>
      order.status.includes(status)
    );
  const [showReplacementOrderModal, setShowReplacementOrderModal] = useState(false);
  const mappedFulfillments = mapFulfillments(order);

  const handleUpdateFulfillment = () => {
    setIsProcessLoading(true);
    axios
      .put(`/orders/mark_fulfillment_delivery_failed/`, { fulfillment_id: selectedFulfilmentId })
      .then(() => {
        refreshCustomer();
        setIsProcessLoading(false);
        setSelectedFulfilmentId(null);
        toast.success('Fulfillment status updated successfully', BOTTOM_TOAST);
      })
      .catch(() => {
        setIsProcessLoading(false);
        setSelectedFulfilmentId(null);
        toast.error('Failed to update fulfillment status', BOTTOM_TOAST);
      });
  };

  const getConfirmationTitle = () => {
    const fulfillment = order.fulfillments.find((fulfillment: any) => {
      return fulfillment.id === selectedFulfilmentId;
    });
    return (
      <p>
        Mark {getPetName(order, fulfillment)}'s fulfillment as{' '}
        <span className="font-bold">“Delivery Failed”?</span>
      </p>
    );
  };

  const getConfirmationMessage = () => {
    return (
      <p>
        This action will update the fulfillment’s status from{' '}
        <span className="font-bold">“Shipped”</span> to{' '}
        <span className="font-bold">“Delivery Failed.”</span>
      </p>
    );
  };

  const isFulfillmentSplittable = (fulfillment: Fulfillment) => {
    return (
      !fulfillment.is_split_fulfillment &&
      fulfillment.status === FulfillmentStatus.ORDERDESK_SENT &&
      fulfillment.products.length > 1
    );
  };

  const getFulfillmentNumber = (product: any) => {
    if (product.fulfillment.is_split_fulfillment || product.isMealProduct) {
      return (
        <div className="flex items-center space-x-1">
          <span>{product.fulfillment?.status ? product.fulfillment?.status : ''}</span>
          {product.fulfillment?.status === 'SHIPPED' && (
            <a
              onClick={() => setSelectedFulfilmentId(product.fulfillment.id)}
              className="flex items-center cursor-pointer underline text-anchor"
            >
              <Pencil className="w-4 h-4 text-black" />
            </a>
          )}
        </div>
      );
    }
    return '--';
  };

  const seenFulfillmentIds = new Set<string>();

  return (
    <div className="pt-3 pb-6">
      <h2 className="mb-1 flex items-center">
        <b className="inline-block">Fulfillments</b>
        {canReplaceOrder && (
          <div className="text-sm ml-2">
            <a
              onClick={() => setShowReplacementOrderModal(true)}
              className="cursor-pointer underline text-anchor"
            >
              Replace Order
            </a>
          </div>
        )}
      </h2>
      {showReplacementOrderModal && (
        <ReplacementOrderModal
          customer={customer}
          order={order}
          onConfirm={() => setShowReplacementOrderModal(false)}
          onCancel={() => setShowReplacementOrderModal(false)}
        />
      )}
      <div className="bg-white">
        <FulfillmentsTable
          header={FulfillmentHeader}
          data={mappedFulfillments.map((product: any) => {
            return {
              'Fulfillment ID': (() => {
                if (seenFulfillmentIds.has(product.fulfillment.id)) {
                  return '--';
                } else {
                  seenFulfillmentIds.add(product.fulfillment.id);
                  return product.fulfillment.id;
                }
              })(),
              Is_Meal_Product: product.isMealProduct,
              Is_Splittable: isFulfillmentSplittable(product.fulfillment),
              'Fulfillment Status': getFulfillmentNumber(product),
              'Shipment Tracking Number(s)': getTrackingNumber(product),
              Pet: getPetNameFromPlanId(order.products, product.fulfillment.pet),
              'Product Code': product.code,
              'Product Description': product.name,
              Price: <ProductPrice product={product} />,
              'Price with Tax': `$${calculatePriceAfterDiscountWithTax(product)}`,
            };
          })}
        />
      </div>
      {selectedFulfilmentId && (
        <ConfirmationModal
          isProcessLoading={isProcessLoading}
          title={getConfirmationTitle()}
          message={getConfirmationMessage()}
          confirmLabel="Confirm"
          onConfirm={handleUpdateFulfillment}
          onCancel={() => setSelectedFulfilmentId(null)}
        />
      )}
    </div>
  );
};
