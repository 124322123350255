import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { AddPetModal } from './AddPetModal';
import { Badge } from './Badge';
import { CancelPetPlanModal } from './CancelPetPlanModal';
import { ConfirmationModal } from './ConfirmationModal';
import { EditButton } from './EditButton';
import { Table } from './Table';
import { SurveyResponseToolTip } from './Tooltip';
import { UpdatePetModal } from './UpdatePetModal';
import { axios } from '../api';
import { Pencil } from '../assets/Pencil';
import { formatDate } from '../dateUtils';
import { Customer, Pet, PetStatus } from '../pages/types';
import { sortPetBreeds, updatePetStatus } from '../petApiUtils';
import { CustomerPlansHeader } from '../tableHeaders';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

interface CustomerPlansProps {
  customer: Customer;
}

interface StatusBadgeProps {
  status: PetStatus;
  petId: string;
  surveyResponse?: any;
}

const DefaultPetData: Pet = {
  id: 'UNKNOWN',
  status: PetStatus.UKNOWN,
  name: 'UNKNOWN',
  gender: 'UNKNOWN',
};

const capitalize = (input: string) => {
  if (input.length === 0) {
    return input; // Return an empty string if input is empty
  }
  input = input.toLowerCase();
  return input.charAt(0).toUpperCase() + input.slice(1);
};

const getPetData = (customer: Customer, targetPetId: string) =>
  customer.pets.find((pet: Pet) => pet.id === targetPetId) ?? DefaultPetData;

export const CustomerPlans = ({ customer }: CustomerPlansProps) => {
  const [showAddPetModal, setShowAddPetModal] = useState(false);
  const [petIdToUpdate, setPetIdToUpdate] = useState<string | null>(null);
  const [petIdToCancel, setPetIdToCancel] = useState<string | null>(null);
  const [petOptions, setPetOptions] = useState<null | any>(null);
  const [showCancelPetPlanModal, setShowCancelPetPlanModal] = useState(false);
  const [petIdToDeceasedNotList, setPetIdToDeceasedNotList] = useState<string | null>(null);
  const [showDeceasedToNotListModal, setShowDeceasedToNotListModal] = useState(false);

  useEffect(() => {
    axios
      .get(`pet_form_options`)
      .then((response) => {
        setPetOptions(response?.data);
      })
      .catch((e) => {
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
  }, []);

  const lifestyleOptions = useMemo(() => {
    const list = (
      petOptions?.lifestyles?.map((lifeStyle: any) => {
        return {
          label: lifeStyle.name,
          value: lifeStyle.slug,
        };
      }) ?? []
    ).sort((a: any, b: any) => a.label.localeCompare(b.label));
    return list;
  }, [petOptions]);

  const bodyTypeOptions = useMemo(() => {
    const list = (
      petOptions?.body_types?.map((bodyType: any) => {
        return {
          label: bodyType.name,
          value: bodyType.slug,
        };
      }) ?? []
    ).sort((a: any, b: any) => b.label.localeCompare(a.label));
    return list;
  }, [petOptions]);

  const healthConditionOptions = useMemo(() => {
    return (
      petOptions?.health_conditions?.map((healthCondition: any) => {
        return {
          label: healthCondition.name,
          value: healthCondition.slug,
        };
      }) ?? []
    );
  }, [petOptions]);

  const breedOptions = useMemo(() => {
    const unsortedBreedOptions =
      petOptions?.breeds.map((breed: any) => {
        return {
          label: breed.name,
          value: breed.slug,
        };
      }) ?? [];

    return sortPetBreeds(unsortedBreedOptions);
  }, [petOptions]);

  const updateDeceasedToNotList = async (petId: string) => {
    await updatePetStatus({ petId, petStatus: PetStatus.DECEASED_TO_NOT_LIST })
      .then(() => {
        toast.success('Pet status set to Deceased_to_not_list successfully!', BOTTOM_TOAST);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((e) => {
        toast.error(
          `Error when setting pet status to Deceased_to_not_list: ${generateErrorMsg(e)}`,
          BOTTOM_TOAST
        );
      });
    setShowDeceasedToNotListModal(false);
  };

  const StatusBadge = ({ status, petId, surveyResponse }: StatusBadgeProps) => {
    if (status === PetStatus.ACTIVE) {
      return (
        <div className="flex">
          <Badge label="Active" />
          <EditButton
            onClickHandler={() => {
              setPetIdToCancel(petId);
              setShowCancelPetPlanModal(true);
            }}
          />
        </div>
      );
    } else if (status === PetStatus.CANCELLED) {
      return (
        <div className="flex">
          <Badge label="Cancelled" variant="secondary" />
          {surveyResponse && (
            <div className="ml-1">
              <SurveyResponseToolTip
                date={formatDate(surveyResponse.created)}
                primaryReason={surveyResponse.primary_answer}
                secondaryReason={surveyResponse.secondary_answer}
                comments={surveyResponse.customer_comments}
              />
            </div>
          )}
          <EditButton
            onClickHandler={() => {
              setPetIdToDeceasedNotList(petId);
              setShowDeceasedToNotListModal(true);
            }}
          />
        </div>
      );
    }
    return <Badge label={capitalize(status)} variant="secondary" />;
  };

  const mapPetPlans = (customer: Customer) => {
    const orderProducts = [];
    // Handle Pet Plans with Product Orders first
    for (const petPlan of customer.petplans) {
      const pet = getPetData(customer, petPlan.pet);
      // Handle MEAL first within the petplan
      for (const product of petPlan.products) {
        // TODO: rename product to petPlanProduct
        if (product.product_type === 'MEAL') {
          const surveyResponse = customer.survey_responses
            .reverse()
            .find((surveyResponse) => surveyResponse.pet_plan_product === product.id);
          orderProducts.push({
            'Pet Status': (
              <StatusBadge status={pet.status} petId={pet.id} surveyResponse={surveyResponse} />
            ),
            'Pet Name': (
              <a
                onClick={() => setPetIdToUpdate(pet.id)}
                className="flex items-center cursor-pointer underline text-anchor space-x-1"
              >
                <span>{pet.name}</span>
                <Pencil className="w-4 h-4 text-black" />
              </a>
            ),
            'Pet Gender': pet.gender,
            'Pet ID': pet.id.slice(0, 5),
            'Product Code': product.code ?? '--',
            Frequency: product.frequency ? `Every ${product.frequency} weeks` : '--',
            'Product Description': product.name ?? '--',
            'Topper Plan': petPlan.is_topper ? 'True' : 'False',
            Cost: `${product.quantity} X $${product.unit_price}`,
            'Next Order': petPlan.status !== 'ACTIVE' ? '--' : petPlan.next_charge_date,
          });
        }
      }
      // Handle SNACKS, SUPPLEMENTS, Etc next within the petplan
      for (const product of petPlan.products) {
        if (product.product_type !== 'MEAL' && product.status === 'ACTIVE') {
          orderProducts.push({
            'Pet Status': '',
            'Pet Name': '',
            'Pet Gender': '',
            'Pet ID': '',
            'Product Code': product.code ?? '--',
            Frequency: product.frequency ? `Every ${product.frequency} weeks` : '--',
            'Product Description': product.name ?? '--',
            'Topper Plan': '--',
            Cost: `${product.quantity} X $${product.unit_price}`,
            'Next Order': petPlan.status !== 'ACTIVE' ? '--' : petPlan.next_charge_date,
          });
        }
      }
    }

    // Handle Pet Plans without Product Orders last
    for (const petPlan of customer.petplans) {
      const pet = getPetData(customer, petPlan.pet);
      if (petPlan.products.length === 0) {
        orderProducts.push({
          'Pet Status': <StatusBadge status={pet.status} petId={pet.id} />,
          'Pet Name': pet.name,
          'Pet Gender': pet.gender,
          'Pet ID': pet.id.slice(0, 5),
          'Product Code': '--',
          Frequency: '--',
          'Product Description': '--',
          'Topper Plan': '--',
          Cost: '--',
          'Next Order': '--',
        });
      }
    }

    return orderProducts;
  };

  return (
    <div className="py-3">
      {showAddPetModal && (
        <AddPetModal
          customer={customer}
          lifestyleOptions={lifestyleOptions}
          healthConditionOptions={healthConditionOptions}
          bodyTypeOptions={bodyTypeOptions}
          breedOptions={breedOptions}
          onConfirm={() => {
            setShowAddPetModal(false);
          }}
          onCancel={() => {
            setShowAddPetModal(false);
          }}
        />
      )}
      {petIdToUpdate && (
        <UpdatePetModal
          customer={customer}
          petId={petIdToUpdate}
          lifestyleOptions={lifestyleOptions}
          bodyTypeOptions={bodyTypeOptions}
          breedOptions={breedOptions}
          healthConditionOptions={healthConditionOptions}
          onConfirm={() => {
            setPetIdToUpdate(null);
          }}
          onCancel={() => {
            setPetIdToUpdate(null);
          }}
        />
      )}
      {showCancelPetPlanModal && petIdToCancel && (
        <CancelPetPlanModal
          petIdToCancel={petIdToCancel}
          customer={customer}
          onCancel={() => {
            setPetIdToCancel(null);
            setShowCancelPetPlanModal(false);
          }}
        />
      )}
      {showDeceasedToNotListModal && petIdToDeceasedNotList && (
        <ConfirmationModal
          title={
            "Would you like to update this pet's status from Cancelled to Deceased_to_not_list?"
          }
          message={"This action will hide the dog's profile from the Customer Portal"}
          confirmLabel="Confirm"
          onConfirm={() => updateDeceasedToNotList(petIdToDeceasedNotList)}
          onCancel={() => setShowDeceasedToNotListModal(false)}
        />
      )}
      <h2 className="mb-1 flex items-center">
        <b className="inline-block">Dogs & Plans</b>
        <div className="text-sm ml-2">
          <a
            onClick={() => setShowAddPetModal(true)}
            className="cursor-pointer underline text-anchor"
          >
            Add a New Dog
          </a>
        </div>
      </h2>
      <div className="bg-white">
        <Table header={CustomerPlansHeader} data={mapPetPlans(customer)} />
      </div>
    </div>
  );
};
